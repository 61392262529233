<template>
  <div
    class="
      tw-flex
      tw-justify-center
      tw-items-center
      tw-bg-white
      tw-bg-opacity-50
      tw-border-2
      tw-border-dashed
      tw-border-gray-300
    "
  >
    <slot>
      <h4 class="tw-text-gray-400">No records found</h4>
    </slot>
  </div>
</template>
