<script setup>

import {computed, ref} from '@vue/reactivity'
import {watch} from '@vue/runtime-core'
import SelectProducts from './SelectProducts.vue';
import _ from 'lodash'
import FilterDropdown from './FilterDropdown.vue';
import NoResults from '../../../../components/NoResults.vue';
import DeleteConfirmationModal from '@/components/commons/DeleteConfirmationModal.vue'

const showDeleteConfirmationModal = ref(false)
const showDeleteConfirmationModalForSingleProduct = ref(false)
const productToBeDeleted = ref({})
const productToBeDeletedIndex = ref(0)

const removeSelectedProducts = () => {

  let selectedIds = bulkSelectedProducts.value.map(x => x.id)
  products.value = _.filter(products.value, (product) => {
    return !(selectedIds.includes(product.id))
  })
}

const props = defineProps({
  productsMargin: {
    type: Array,
    default: [],
  },
  isDefaultPriceList: {
    type: Boolean,
    default: false,
  },
  priceListType: {
    type: String,
    default: 'variable'
  },
  defaultMargin: {
    type: Number,
    default: 0
  }
})

const emits = defineEmits(['productMarginUpdated'])


const showProductSelection = ref(false)
const products = ref(_.cloneDeep(props.productsMargin))



watch(products, (val, oldVal) => {
  emits('productMarginUpdated', val)
}, {deep: true, immediate: true})


const handleProductSelection = (items) => {
  const result = items.filter(x => {
    return !products.value.some(y => y.product_grade_id === x.product_grade_id && y.product_type_id === x.product_type_id && y.product_style_id === x.product_style_id)
  })

  products.value.push(...result)
  _.forEach(products.value, i => {
    if (!i.margin) {
      i.margin = props.defaultMargin
      updateMargin(i)
    }
  })
}

const setToBeDeletedItemData = (item, index) => {
  productToBeDeleted.value = item
  productToBeDeletedIndex.value = index
}
const removeProduct = () => {

  if(products.value[productToBeDeletedIndex.value] ===  productToBeDeleted.value) {
    products.value.splice(productToBeDeletedIndex.value, 1)
  }
}

const bulkSelectedProducts = ref([])

const filteredProducts = computed(() => {
  let res = products.value

  if(selectedTypesForFilter.value.length) {
    res = res.filter(i => selectedTypesForFilter.value.includes(i.product_type_id))
  }

  if(selectedStylesForFilter.value.length) {
    res = res.filter(i => selectedStylesForFilter.value.includes(i.product_style_id))
  }

  if(selectedGradesForFilter.value.length) {
    res = res.filter(i => selectedGradesForFilter.value.includes(i.product_grade_id))
  }

  return res
})

const selectedTypesForFilter = ref([])
const selectedStylesForFilter = ref([])
const selectedGradesForFilter = ref([])

const typesDropdownItemList = computed(() => {
  const res = []
  products.value.forEach(i => {
    if(!res.find(x => x.id === i.product_type_id)) {
      res.push({
        id: i.product_type_id,
        name: i.product_type_name,
      })
    }
  })
  return res
})

const stylesDropdownItemList = computed(() => {
  const res = []
  products.value.forEach(i => {
    if(!res.find(x => x.id === i.product_style_id)) {
      res.push({
        id: i.product_style_id,
        name: i.product_style_name,
      })
    }
  })
  return res
})

const gradesDropdownItemList = computed(() => {
  const res = []
  products.value.forEach(i => {
    if(!res.find(x => x.id === i.product_grade_id)) {
      res.push({
        id: i.product_grade_id,
        name: i.product_grade_name,
      })
    }
  })
  return res
})

const handleBulkSelection = (e) => {
    bulkSelectedProducts.value = e.target.checked? filteredProducts.value: []
}

const bulkMargin = ref(0)

const commitBulkMarginUpdate = () => {
  bulkSelectedProducts.value.forEach(i => {
    i.margin = bulkMargin.value.replaceAll(',','.')
    updateMargin(i)
  })
}

const cancelBulkMarginUpdate = () => {
  bulkSelectedProducts.value = []
  bulkMargin.value = 0
}
// const va = ref(1)
// const tmp = () => {
//   va.value = va.value + 1
//   emits('productMarginUpdated', [`sss${va.value}`])
// }

const updateMargin = (item) => {
  item.price = (parseFloat(item.cost) * ((100 + parseFloat(item.margin)) / 100)).toFixed(2);
}

const updatePrice = (item) => {
  if (item.cost == 0)
    item.margin = props.defaultMargin
  else
    item.margin = ((item.price - item.cost) / item.cost * 100).toFixed(1)
}
_.forEach(products.value, i => {
  if (props.priceListType === 'variable')
    updateMargin(i)
  else
    updatePrice(i)
})
</script>
<template>
  <div class="tw-text-black-gray">
    <!-- <button type="button" @click="tmp">Sync...</button> -->
    <!-- Header -->
    <div class="tw-flex tw-bg-secondary tw-py-2 tw-px-3 tw-justify-between tw-flex-wrap tw-gap-4" >
        <!-- Filters -->
        <div class="tw-flex tw-flex-1- tw-gap-4 tw-items-center tw-flex-wrap" >
            <span class="tw-text-white tw-font-medium tw-tracking-wider tw-uppercase tw-text-sm">Filters:</span>
            <div class="tw-flex tw-gap-4 tw-flex-wrap">
              <FilterDropdown name="Type" :filterList="typesDropdownItemList" :selectedFilters="selectedTypesForFilter" @selected="(val) => {selectedTypesForFilter = val; bulkSelectedProducts = []}"></FilterDropdown>
              <FilterDropdown name="Style" :filterList="stylesDropdownItemList" :selectedFilters="selectedStylesForFilter" @selected="(val) => {selectedStylesForFilter = val; bulkSelectedProducts = []}"></FilterDropdown>
              <FilterDropdown name="Grade" :filterList="gradesDropdownItemList" :selectedFilters="selectedGradesForFilter" @selected="(val) => {selectedGradesForFilter = val; bulkSelectedProducts = []}"></FilterDropdown>
            </div>
        </div>
        <!-- Actions -->
        <div class="tw-flex tw-items-center tw-gap-4">
            <div v-if="bulkSelectedProducts.length > 1" class="tw-flex tw-items-center tw-gap-4">
              <span class="tw-text-white tw-font-medium tw-tracking-wider tw-uppercase tw-text-sm tw-whitespace-nowrap">BULK EDIT:</span>
              <label class="tw-flex tw-items-center ">
                <span class="tw-text-white tw-font-medium tw-tracking-wider tw-uppercase tw-text-sm tw-whitespace-nowrap">Margin:</span>
                <input
                    type="text"
                    class="form__input tw-w-14 tw-text-right tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-1 tw-py-0.5"
                    name="query"
                    placeholder=""
                    v-model="bulkMargin"
                  />
              </label>
              <button type="button" @click="commitBulkMarginUpdate" class="tw-btn-tertiary-success tw-bg-transparent tw-p-0 hover:tw-bg-transparent">
                <svg-icon name="solid/check"></svg-icon>
              </button>
              <button type="button" @click="cancelBulkMarginUpdate" class="tw-btn-tertiary-success tw-bg-transparent tw-p-0 hover:tw-bg-transparent">
                <svg-icon name="solid/times"></svg-icon>
              </button>
              <button type="button" @click="showDeleteConfirmationModal = true"  class="tw-btn-tertiary-success tw-bg-transparent hover:tw-bg-transparent tw-p-0 tw-text-white">
                <svg-icon name="solid/trash"></svg-icon>
              </button>
            </div>

            <button @click.prevent="showProductSelection = true" class="tw-w-28 tw-whitespace-nowrap tw-btn-secondary-success tw-bg-transparent hover:tw-bg-primary hover:tw-text-white">
                Add New
            </button>
        </div>
    </div>

    <!-- Body -->
    <div class="tw-overflow-auto tw-shadow">
      <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-divide-solid">
        <thead class="tw-bg-gray-50">
          <th scope="col" class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            <label class="form__label form__label_row tw-m-0 tw-p-0">
              <input
                type="checkbox"
                class="form__radio"
                hidden
                :checked="bulkSelectedProducts.length == filteredProducts.length"
                @change="handleBulkSelection"
              />
              <span class="form__checkbox_icon tw-m-0" ></span>
            </label>
          </th>
          <th scope="col" class="tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Type
          </th>
          <th scope="col" class="tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Style
          </th>
          <th scope="col" class="tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Grade
          </th>
          <th scope="col" class="tw-w-20 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Cost
          </th>
          <th scope="col" class="tw-w-20 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Margin(%)
          </th>
          <th scope="col" class="tw-w-20 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Price
          </th>
          <th scope="col" class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">

          </th>
        </thead>
        <tbody class="tw-divide-solid tw-divide-y tw-divide-gray-200">
          <template v-if="filteredProducts.length === 0">
            <tr>
              <td colspan="8">
                <NoResults class="tw-h-52"></NoResults>
              </td>
            </tr>
          </template>
          <template v-else>

            <tr v-for="(item, index) in filteredProducts" :key="`${item.product_type_id}-${item.product_style_id}-${item.product_grade_id}`">
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                <label class="form__label form__label_row tw-m-0 tw-p-0">
                  <input
                    type="checkbox"
                    class="form__radio"
                    :value="item"
                    hidden
                    v-model="bulkSelectedProducts"
                    name="bulkProducts"
                  />
                  <span class="form__checkbox_icon tw-m-0"></span>
                </label>
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                {{item.product_type?.name || item.product_type_name}}
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                {{item.product_style?.name || item.product_style_name }}
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                {{item.product_grade?.name || item.product_grade_name}}
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                  {{$filters.formatMoney(item.cost)}}
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap tw-group group">
                <div class="tw-relative tw-group--">
                  <Form as="div" v-slot="{errors}">
                    <Field
                      :rules="{ required: true ,min_value : 0,max_value:1000,decimal:2}"
                        type="number"
                        class="form__input tw-text-right tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-0.5 tw-py-0.5"
                        :class="{'tw-border tw-border-red-500 tw-border-solid hover:tw-border-red-500': !!errors[`${index}_margin_${item.product_type_id}`]}"
                        placeholder=""
                        label="margin"
                        :name="`${index}_margin_${item.product_type_id}`"
                        v-model="item.margin"
                        @update:modelValue="updateMargin(item)"
                      />
                    <br>
                    <p class="group-hover:tw-hidden form__error tw-absolute tw-right-0 tw-bottom-0 tw-bg-red-200 tw-text-red-600 tw-p-1 tw-rounded tw-px-1.5 tw-transform tw-translate-y-full tw-shadow tw-border tw-border-white tw-border-solid" v-if="errors[`${index}_margin_${item.product_type_id}`]">Error</p>
                    <ErrorMessage class="hover:tw-z-10 tw-hidden group-hover:tw-block group-hover:tw-z-10 form__error tw-absolute tw-right-0 tw-bottom-0 tw-bg-red-200 tw-text-red-600 tw-p-1 tw-rounded tw-px-1.5 tw-transform tw-translate-y-full tw-shadow tw-border tw-border-white tw-border-solid tw-border-r-2"  :name="`${index}_margin_${item.product_type_id}`" />
                  </Form>
                </div>
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap tw-group">
              <div class="tw-relative tw-group--">
                <Form as="div" v-slot="{errors}">
                  <Field
                    :rules="{ required: true ,min_value : 0,decimal:2}"
                    type="number"
                    class="form__input tw-text-right tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-0.5 tw-py-0.5"
                    :class="{'tw-border tw-border-red-500 tw-border-solid hover:tw-border-red-500': !!errors[`${index}_price_${item.product_type_id}`]}"
                    :name="`${index}_price_${item.product_type_id}`"
                    placeholder=""
                    label="price"
                    v-model="item.price"
                    @update:modelValue="updatePrice(item)"
                  />
                  <br>
                  <p class="group-hover:tw-hidden form__error tw-absolute tw-right-0 tw-bottom-0 tw-bg-red-200 tw-text-red-600 tw-p-1 tw-rounded tw-px-1.5 tw-transform tw-translate-y-full tw-shadow tw-border tw-border-white tw-border-solid" v-if="errors[`${index}_price_${item.product_type_id}`]">Error</p>
                  <ErrorMessage class="hover:tw-z-10 tw-hidden group-hover:tw-block group-hover:tw-z-10 form__error tw-absolute tw-right-0 tw-bottom-0 tw-bg-red-200 tw-text-red-600 tw-p-1 tw-rounded tw-px-1.5 tw-transform tw-translate-y-full tw-shadow tw-border tw-border-white tw-border-solid tw-border-l-2"   :name="`${index}_price_${item.product_type_id}`" />
                </Form>
              </div>
              </td>
              <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                  <button type="button" @click="setToBeDeletedItemData(item, index),showDeleteConfirmationModalForSingleProduct = true" class="tw-btn-tertiary-success tw-text-red-900">
                    <svg-icon name="solid/trash"></svg-icon>
                  </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <DeleteConfirmationModal v-model:show="showDeleteConfirmationModal" message="This will remove all selected products. Are you sure you wants to continue?"
                             @continue="removeSelectedProducts()"/>

    <DeleteConfirmationModal v-model:show="showDeleteConfirmationModalForSingleProduct" message="This will remove product from the list. Are you sure you wants to continue?"
                             @continue="removeProduct()"/>
    <SelectProducts v-if="showProductSelection" @close="showProductSelection = false" @select-products="handleProductSelection"></SelectProducts>
  </div>
</template>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
