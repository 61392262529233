<script setup>
import { computed, ref } from "@vue/reactivity";
import _ from "lodash";
import Modal from "../../../../components/modals/Modal.vue";
import SimpleList from "./SimpleList.vue";
import useGetProducts from "../../../../hooks/price-lists/useGetProducts";
import useMakeProductsForTypeStyleGrade from "../../../../hooks/price-lists/useMakeProductsForTypeStyleGrade";
import {watch} from "@vue/runtime-core";

const emits = defineEmits(['close', 'selectProducts'])

const { makeProductsForTypeStyleGrade, makeProductsForTypeStyleGradeStatus } = useMakeProductsForTypeStyleGrade()

const typesQuery = useGetProducts('types');
const stylesQuery = useGetProducts('styles')
const gradesQuery = useGetProducts('grades');

const selectedTypes = ref([]);
const selectedStyles = ref([]);
const selectedGrades = ref([]);

const selectAllTypes = ref(true)
const selectAllStyles = ref(true)
const selectAllGrades = ref(true)

const getProductsForSelection = async () => {
    await makeProductsForTypeStyleGrade({
        typeIds: selectedTypes.value,
        styleIds: selectedStyles.value,
        gradeIds: selectedGrades.value,
    })

    emits('selectProducts', makeProductsForTypeStyleGradeStatus.data)
    emits('close')
}

const styles = computed(() => {
  if (selectedTypes.value.length === 0) return stylesQuery.data.value;

  const stylesForSelection = _.filter(stylesQuery.data.value, (s) => {
    return (
      _.filter(selectedTypes.value, (t) => {
        return s.matrices_types.includes(t);
      }).length > 0
    );
  });

  return stylesForSelection
});

const grades = computed(() => {

  return _.filter(gradesQuery.data.value, (g) => {
    var tm = true,
      sm = true,
    all = true;

    if (selectedTypes.value.length > 0)
      tm =
        _.filter(selectedTypes.value, (t) => {
          return (
            _.filter(g.types, (gt) => {
              return t == gt.type;
            }).length > 0
          );
        }).length > 0;

    if (selectedStyles.value.length > 0)
      sm =
        _.filter(selectedStyles.value, (s) => {
          return _.filter(g.types, gt => {
                    return s == gt.style_id
                }).length > 0


        }).length > 0;

    if (selectedStyles.value.length > 0 && selectedTypes.value.length > 0){

      const data = [ Object.values(selectedTypes.value), Object.values(selectedStyles.value)];
      const crossJoin = data.reduce((acc, curr) =>
        acc.flatMap(c => curr.map(n => [].concat({type:c, style_id:n})))
      )

      all = _.filter(g.types,(gt) => {
              return _.filter(crossJoin,(t) => {
                return gt.type === t[0].type && gt.style_id === t[0].style_id
              }).length > 0
      }).length > 0
    }

    return tm && sm && all;
  });
});

const multiple = ref(true);
const step = ref("type");

const changeStep = (val) => {
  step.value = val;
};

const handleTypeSelection = (items) => {
    selectedTypes.value = items
    selectedStyles.value = []
    selectAllStyles.value = true
}

const handleStyleSelection = (items) => {
    selectedStyles.value = items
    selectedGrades.value = []
    selectAllGrades.value = true
}

const handleGradeSelection = (items) => {
    selectedGrades.value = items
}

</script>
<template>
  <Modal v-if="true">
    <div class="tw-card-modal tw-mx-4 tw-my-8 tw-max-w-5xl tw-z-10">
        <div
          class="tw-card-head-modal tw-flex tw-justify-between tw-items-center"
        >
          <h4 class="tw-font-normal">
            Select products
          </h4>
          <button
            :disabled="makeProductsForTypeStyleGradeStatus.isLoading"
            type="button"
            class="btn btn_transparent tw-m-0 tw-border-none"
            @click="emits('close')"
          >
            <svg-icon name="solid/times"></svg-icon>
          </button>
        </div>

        <div class="tw-card-body-modal with-scroll" :class="{'tw-animate-pulse': makeProductsForTypeStyleGradeStatus.isLoading}">
          <!-- Selections -->
          <div class="container container__white">
            <div class="row">
              <div class="col-12 col-md-3">
                <div class="container" v-if="multiple">
                  <div
                    class="row entry cursor_pointer"
                    :class="{ active: step == 'type' }"
                    @click="changeStep('type')"
                  >
                    <div class="col-12">
                      Types
                      <p
                        class="text_small pl-3"
                        v-if="selectAllTypes"
                      >
                        All Types
                      </p>
                      <p class="text_small pl-3" v-else>
                        {{ selectedTypes.length }} Types Selected
                      </p>
                    </div>
                  </div>
                  <div
                    class="row entry cursor_pointer"
                    :class="{ active: step == 'style' }"
                    @click="changeStep('style')"
                  >
                    <div class="col-12">
                      Styles
                      <p
                        class="text_small pl-3"
                        v-if="selectAllStyles"
                      >
                        All Styles
                      </p>
                      <p class="text_small pl-3" v-else>
                        {{ selectedStyles.length }} Styles Selected
                      </p>
                    </div>
                  </div>
                  <div
                    class="row entry cursor_pointer"
                    :class="{ active: step == 'grade' }"
                    @click="changeStep('grade')"
                  >
                    <div class="col-12">
                      Grades
                      <p
                        class="text_small pl-3"
                        v-if="selectAllGrades"
                      >
                        All Grades
                      </p>
                      <p class="text_small pl-3" v-else>
                        {{ selectedGrades.length }} Grades Selected
                      </p>
                    </div>
                  </div>
                </div>
                <div class="container" v-else></div>
              </div>

              <div class="col-12 col-md-9">

                <simple-list
                  v-show="step == 'type'"
                  :multiple="multiple"
                  :selected="selectedTypes"
                  @selection="handleTypeSelection"
                  @selectAll="(val) => { selectedTypes = []; selectAllTypes = val }"
                  :list="typesQuery.data.value || []"
                  :loading="typesQuery.isLoading.value"
                  :loading_text="'Loading types'"
                  :select-all="selectAllTypes"
                />
                <simple-list
                  v-show="step == 'style'"
                  :multiple="multiple"
                  :selected="selectedStyles"
                  @selection="handleStyleSelection"
                  @selectAll="(val) => { selectedStyles = []; selectAllStyles = val }"
                  :list="styles || []"
                  :loading="stylesQuery.isLoading.value"
                  :loading_text="'Loading styles'"
                  :select-all="selectAllStyles"
                />
                <simple-list
                  v-show="step == 'grade'"
                  :multiple="multiple"
                  :selected="selectedGrades"
                  @selection="handleGradeSelection"
                  @selectAll="(val) => { selectedGrades = []; selectAllGrades = val }"
                  :list="grades || []"
                  :loading="gradesQuery.isLoading.value"
                  :loading_text="'Loading grades'"
                  :select-all="selectAllGrades"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            tw-card-footer-modal tw-bg-secondary tw-text-white tw-space-x-4
          "
        >
          <button
            :disabled="makeProductsForTypeStyleGradeStatus.isLoading"
            @click="getProductsForSelection"
            type="button"
            class="btn btn_transparent tw-m-0 tw-space-x-2"
          >
            <span>Select</span>
          </button>
        </div>
    </div>


  </Modal>
</template>

<style scoped lang="scss">
.entry {
  background-color: #eeeeee;
  margin: 3px auto;
  padding: 5px;

  &:hover {
    background-color: rgba(85, 180, 148, 0.4);
  }

  &.active {
    background-color: rgba(85, 180, 148, 0.4);
  }
}
</style>
