<script setup>
import { computed, ref, reactive, toRef, toRefs } from "@vue/reactivity";
import { watch } from '@vue/runtime-core'
import _ from "lodash";
// import InlinePreloader from "@/components/InlineLoadr.vue";
import InlinePreloader from "../../../../components/parts/inlinePreloader.vue";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: true,
  },  
  selected: {
    type: Array,
    default: [],
  },
  selectAll: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["selection", "selectAll"]);
const loading_text = "loading...";
const query = ref("");


const selectedItems = computed({
  get: () => props.selected,
  set: (val) => {
    emit("selection", val);
  },
});

const selectAll = computed({
  get: () => props.selectAll,
  set: (val) => {
    emit("selectAll", val)
  },
});

const items = computed(() => {
  if (query.value)
    return _.filter(props.list, (i) => {
      return i.name.toLowerCase().indexOf(query.value.toLowerCase()) >= 0;
    });
  return props.list;
});
</script>

<template>
  <div class="container container_white">
    <div class="row">
      <div class="col-12">
        <div class="row entry">
          <div class="col-12">
            <label class="form__label form__label_row">
              <input
                type="checkbox"
                class="form__radio"
                value="true"
                hidden
                v-model="selectAll"
              />
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle text_bold dealer_name">Select All</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label class="form__label form__label_row">
          <input
            type="text"
            class="form__input"
            name="query"
            v-model="query"
            :disabled="selectAll"
            placeholder="Start typing to filter"
          />
        </label>
      </div>
      <div class="col-9"></div>
    </div>

    <div class="row" v-if="loading">
      <div class="col-12">
        <inline-preloader :message="loading_text" />
      </div>
    </div>
    <div v-else :class="{'tw-opacity-60 tw-pointer-events-none': selectAll}">
      <div
        class="row entry"        
        v-for="(item, k) in items"
        :key="k"
      >
        <div class="col-12">
          <label class="form__label form__label_row" v-if="multiple">
            <input
              type="checkbox"
              class="form__checkbox"
              :value="item.id"
              hidden
              v-model="selectedItems"
            />
            <span class="form__checkbox_icon"></span>
            <span class="form__labelTitle text_bold dealer_name">{{
              item.name
            }}</span>
          </label>
          <span class="form__labelTitle text_bold dealer_name" v-else>{{
            item.name
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// @import "../../assets/scss/utils/vars";

.entry {
  background-color: #eeeeee;
  margin: 3px auto;
  padding: 5px;

  &:hover {
    background-color: rgba(85, 180, 148, 0.4);
  }

  .dealer_name {
    font-size: 1.1em;
    color: "#0e3753";
  }
}

.form__input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
</style>
