import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
// import store from "@/store";
import { httpReq } from "@/htttpReq";


const fetchData = async (type) => {

  const response = await httpReq.get(`ordering-portal/get-products/${type}`);
  return response.data.data;
};

export default function useGetProducts(type) {

  const queryKey = ["price-list-products", { type: type }];

  return useQuery(
    queryKey,
    () => fetchData(type),
    {
      refetchOnWindowFocus: false,
      //   placeholderData: [],
      //   staleTime: Infinity,
      onError: (error) => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
