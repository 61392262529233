import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
// import { useToast } from "vue-toastification";
// const toast = useToast();
import { ref, toRefs, computed, reactive } from "@vue/reactivity";

const action = async (payload) => {
  const { typeIds, styleIds, gradeIds } = payload;
  const response = await httpReq.post(`ordering-portal/account-information-maintenance/price-list/products/selected-product-cross-join`, {
    product_type_ids: typeIds,
    product_style_ids: styleIds,
    product_grade_ids: gradeIds,
  });

  return response.data.data
};

export default function useMakeProductsForTypeStyleGrade() {
  const cache = useQueryClient();
  const mutation = useMutation(({ typeIds, styleIds, gradeIds }) => action({typeIds, styleIds, gradeIds}), {
    onError: (error) => {
      store.commit("errorsModal/openModal", error.response.data.errors);
    },
    onSuccess: (data, variables, context) => {
      // cache.invalidateQueries(["price-lists"]);
      // toast.success("Account update.");
    },
    onSettled: (data, error, variables, context) => {},
  });

  return reactive({
    makeProductsForTypeStyleGrade: mutation.mutateAsync,
    makeProductsForTypeStyleGradeStatus: mutation,
  });
}
