<script setup>
import { computed } from '@vue/reactivity'
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import DropDownChevron from '../../../../components/icons/DropDownChevron.vue';

const props = defineProps({
    filterList: {
        type: Array,
        required: true
    },
    selectedFilters: {
        type: Array,
        required: true
    },
    name: {
        type: String,
        required: true,
    }
})

const emits = defineEmits(['selected'])

const selectedItems = computed({
  get: () => props.selectedFilters,
  set: (val) => {
    emits("selected", val);
  },
});


</script>

<template>
  <div>
    <Popover v-slot="{ open }" class="popover">
        <PopoverButton as="button" class="btn btn_default popover-button tw-text-black">
        <span>{{ name }}: {{ filterList.length === selectedFilters.length? 'All': selectedFilters.length > 0? selectedFilters.length: 'Select'}} </span
        >
        <DropDownChevron :is-open="open"></DropDownChevron>
        </PopoverButton>

        <transition name="popover">
        <PopoverPanel class="popover-panel">
            <div class="tw-grid tw-grid-cols-1 tw-gap-1-">
            <label
                v-for="(filter, index) in filterList"
                :key="index"
                class="
                form__label
                tw-flex tw-items-center tw-my-0 tw-text-sm
                popover-menu-item
                tw-cursor-pointer
                "
            >
                <input
                v-model="selectedItems"
                :value="filter.id"
                type="checkbox"
                hidden
                class="form__checkbox"
                />
                <span class="form__checkbox_icon" />
                <span>{{ filter.name }}</span>
            </label>
            </div>
        </PopoverPanel>
        </transition>
    </Popover>
  </div>
</template>

<style scoped>
.popover-panel {
  /* position: fixed; */
  right: auto;
  left: auto;
}
</style>